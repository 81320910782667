@import './../constants/constants.scss';

.table-wrapper {
    // max-height: 60vh;
    overflow-y: auto;
    // height: 60vh;
    max-height: 60vh;
    // min-height: 50vh;
    font-size: 16px;
    border-radius: 5px;
    overflow-anchor: none;    
}

.table-responsive-text {
    @media (max-width: 1200px){
        font-size: 12px;
    }
}

.editable-table-root {
    height: 100%;
    border-collapse: separate;
    width: 100%;
    border-spacing: 0;
    overflow-anchor: none;
    // border-top: 1px solid $table-border-main;
    // border-bottom: 1px solid $table-border-main;

    // th, 
    td {
        border-bottom: 1px solid #f4f4f4;
        // border-right: 1px solid $table-border-main;
        position: relative;
        vertical-align: bottom;
    }

    th {
        padding: 0.3em;
        background-color: #f4f4f4;
        // font-size: 1.2em;
        color: #878686;
        white-space: nowrap;
        // color:#4ab43b;
    }

    td {
        padding: 0;
        font-size: 1em;
        &:hover {
            background-color: $table-hover-main;
        }
        input {
            font-size: 1em;
        }
    }

    tbody{
        tr {
            height: 40px;
        }
        td:last-child {
            &:hover{
                background-color: transparent;
            }
        }
        .disabled-cell {
            height: 100%;
            text-indent: 4px;
            display: flex;
            align-items: center;
            background: rgb(244, 244, 244);
        }
    }
    

    th:last-child, td:last-child {
        border-right: 0;
    }

    > thead{
        position: sticky;
        top: 0px;
        z-index: 3;
    }
    > tfoot {
        position: sticky;
        bottom: 0px;
        z-index: 3;
    }
    tfoot {
        td {
            border: none;
            vertical-align: middle;
            font-weight: bold;
            padding: 0.3em;
            background-color: #f4f4f4;
            // font-size: 1.2em;
            color: #878686;
            white-space: nowrap;
        }
        .button {
           width: 100%;
           svg{
            margin-right: 4px;
           } 
        }
    }

    input {
        border: none;
        outline: none;
        max-width: 100%;
        padding: 0.1em;
        background-color: transparent;
        width: 100%;
        padding: 0.3em;

        &:focus {
            background-color: $table-hover-main;
        }
    }
    .grouped[aria-selected=true] {
        td {
            border-top: 4.5px solid #f8b133;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 0px;
        }
    }
    .grouped[aria-selected=true]+tr > td{
        border-left: 4.5px solid #f8b133;
        border-bottom: 4.5px solid #f8b133;
        padding-top: 1px;
    }
    .grouped[aria-selected=true]+tr+tr > td {
        border-top: none;
    }
    
}


.editable-table-empty-tr {
    color: #878686;
    td {
        height: 120px;
        text-align: center;
        vertical-align: middle;
        font-size: 1.4em;
    }
    svg {
        margin-right: 8px;
    }
}