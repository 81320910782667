.news {
  height: 100%;
  .news-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .news-title {
      font-size: 2em;
      font-weight: 600;
    }
  }

  .news-empty-text {
    background: #f4f4f4;
    height: 100%;
    border-radius: 5px;
    font-weight: 500;
    font-size: 22px;
    color: #878686;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .news-list-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    border-radius: 5px;
  }

  .news-list {
    // flex: 0 1 auto;
    justify-content: space-between;
    height: calc(100% - 90px);
  }

  .news-listitem-wrapper {
    display: flex;
    flex-direction: column;
    // height: 175px;
    height: 32%;
    // min-height: 175px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    padding: 10px 20px;
    @media (max-width: 1200px) {
      height: 175px;
    }
  }

  .news-empty-listitem-wrapper {
    background: #f4f4f4;
    align-items: center;
    justify-content: center;
    color: #878686;
    font-size: 18px;
    font-weight: 500;
  }

  .news-active-listitem-wrapper {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    &:hover {
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }
  
  .news-listitem-wrapper:not(:last-child) {
    // border-bottom: 1px solid #d2d2d2;
    margin-bottom: 14px;

  }

  .news-listitem-titleWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .news-listitem-title {
    font-size: 26px;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .news-listitem-body {
    flex-grow: 1;
    max-height: 95px;
    overflow: hidden;
  }

  .news-listitem-published {
    font-size: 14px;
    text-align: right;
    color: #7c7b7b;
  }

  .news-listitem-tools {
    display: flex;
    gap: 12px;
    align-items: center;
  }
}