.printTableTh{
    text-align: left;  
    margin-top: 50px;
}
.tableRow{   
    width:100%;
    background-color: #eeeeee;
    padding-bottom: 50px;
}
.tableRow:nth-child(odd){
    background-color: #ddd;
}
.tableRow:nth-child(even){
    background-color:white;
}

@media all {
    .pageBreak {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      print-color-adjust: exact;
    }
  }
  
  @media print {
    .pageBreak {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {    
    size: auto;
    margin: 20mm;
    
  }

.alertPrintModal{
    margin-bottom: 8px;
    width: 100%;
    background-color: #f4f4f4;
    color:#878686;
    font-size: small;
    border-radius: 4px;
    word-wrap: break-word;
    align-self: center;
    padding-right: 4px;
    padding-left: 4px;
}
.errorMessagePrint{
    background-color:#f4f4f4;
    position:static;
    font-size:small;
    cursor:pointer;
    border-radius: 4px;
    padding-left: 4px;
    padding-right: 4px;
    opacity:0.8;
    margin-right: 5px;
}  


