.infobar {
  background-color: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 500;
  color: #878686;
  div {

  }
  svg {
    margin-right: 8px
  }
}
.infobar[aria-label='error']{
  background-color: #b30404;
  color: white;
}