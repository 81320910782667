
.testarlite{

    input[type="date"]::-ms-clear{
        display: none;
    }
   
     
}
.editInfoLabel{
    border:2px solid black;
    display: flex;
    width: fit-content;
    margin:0px;
    padding:5px 2px 5px 2px;
}
.tabStyleOveride{
    .nav-tabs{
        --bs-nav-tabs-link-active-color : rgb(0, 0, 0);
       
        font-size: 1em;
        
      }
      .nav-link{
        --bs-nav-link-color: rgb(214, 214, 214);
        background: white;
      }
      .nav{
        --bs-nav-link-hover-color: rgb(0, 0, 0);
        
      }
      .nav-link.active{
        box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
        background: white;
        color:black;
        border-bottom: 1px solid pink;
       // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
    }
    
}
.tabStyleOveride-added{
  
    .nav-link.active{
     
      border-bottom: 2px solid rgb(7, 162, 53);
     // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
  }
  
}
.tabStyleOveride-updated{
  
  .nav-link.active{
   
    border-bottom: 2px solid rgb(248, 176, 51);
   // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
}

}
.tabStyleOveride-deleted{
  
  .nav-link.active{
   
    border-bottom: 2px solid rgb(179, 4, 4);
   // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
}

}
.tabStyleOveride-invoiceReportFieldsUpdated{
  
  .nav-link.active{
   
    border-bottom: 2px solid rgb(89, 191, 235);
   // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
}

}
.tabStyleOveride-kickbackReportChange{
  
  .nav-link.active{
   
    border-bottom: 2px solid rgb(86, 181, 58);
   // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
}

}

.tabTitle{
  font-style: normal !important;
    margin:0px;
    padding:0px; 
}
/* Styling for Child Rows */
.child-row {
  background-color: #fafafa;
}

.child-row-content {
  
  // border: 1px solid #ddd;
  background-color: #f5f5f5;
  //border-radius: 4px;
}
.child-row-content {
  transition: all 0.3s ease;
}






.invoiceReportCardContainer{
  flex-wrap:wrap;
  justify-content:space-evenly;
  max-width:100%;
  margin-bottom:15px;
  display:flex;
  flex-direction:row;
  background:white;
  padding:10px;
  gap:10px;
}
.invoiceReportCardContentContainer{
  border-radius:10px;
  display:flex;
  flex-direction:row;
  
}
.leftSidePanel{
  color:white;
  padding:30px;
  max-width:250px;
  align-content: center;
}
.rightSideContainer{
  position:relative;
  text-align:center;
  padding:10px;
}
.invoiceReportCardContentTitle{
  font-size: 18px;
}
.invoiceReportTextArea{
  display:flex;
  flex-direction:row;
  justify-content:center;
  text-align:center;
  flex-wrap:wrap;
}
.invoiceReportComment{
  display:flex;
  flex-direction:column;
  justify-Content:center;
}
.invoiceReportTranslationField{
  margin-right:5px;
  margin-top:0px;
  margin-bottom:0px;
  font-size: 12px;
}
.invoiceReportTextAreaStyling{
  width:270px;
  height:50px;
  border:2px solid #ccc;
  border-radius:5px;
  font-family:inherit;
  font-size:12px;
  line-height:1.5;
  overflow-Y:scroll;
  white-space:pre-wrap;
  background-color:white;
  box-sizing:border-box;
  resize:none;
}

.dataRow {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
}

.dataField {
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  font-size: 12px;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 90px;
  height: 14px;
  --c:rgb(248, 177, 51,0.5) 92%,#0000;
  background: 
    radial-gradient(circle 7px at bottom,var(--c)) 0 0,
    radial-gradient(circle 7px at top   ,var(--c)) 0 100%;
  background-size: calc(100%/4) 50%;
  background-repeat: repeat-x;
  animation: l11 1s infinite;
}
@keyframes l11 {
    80%,100% {background-position: calc(100%/3) 0,calc(100%/-3) 100%}
}