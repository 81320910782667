.user-nav-profile-root {
    cursor: pointer;
    text-align: center;
    width: 3em;
    height: 3em;
    padding: 0.25em;
    border-radius: 2em;

    .user-nav-profile-initials {
        padding: 0.5em;
        font-size: 1em;
    }
}

.user-nav-profile-popover {
    border-radius: 0 !important;
    box-shadow: 0px 1px 5px #2d2d2d4d;
    max-width: unset !important;

    .user-nav-profile-popover-header {
        background-color: white;
    }

    .user-nav-profile-popover-logout {
        width: 100%;
        padding: 0.5em;
        background-color: white;
        border: none;
        font-size: 1.2em;
        cursor: pointer;
        &:hover {
            background-color: #2d2d2d4d;
            color: white;
        }
    }
}

.user-nav-supplier-list {
    border-bottom: 1px solid #dee2e6;
}

.user-nav-supplier {
    font-size: 16px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    .user-nav-supplierId {
        font-size: 12px;
        font-style: italic;
    }
}