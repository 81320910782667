.test {
  width: "100%";
  display: flex;
  justify-content: space-between;
}
@media only screen and (min-width: 600px) {
  .dashboardPieContainer {
    margin-top: 5px !important;
    width: 45% !important;
  }
  .dashboardChartsscontainer {
    margin-top: 5px !important;
    margin-right: 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .textTest {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 14px;
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .pieLegend {
    
    padding-bottom: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .dashboardCardscontainer {
    display: block !important;
  }
  .test {
    display: block;
  }
  .dashboardCard {
    width: 100% !important;
    margin-bottom: 15px !important;
  }
  .dashboardChartsscontainer {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    float: none !important;
    position: relative !important;
    margin-top: 5px !important;
    width: 100% !important;
    min-width: 100% !important;
    min-height: auto !important;
    margin-bottom: 5px;
  }
  .dashboardChartsscontainerTable {
    font-size: x-small;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .pieLegend {
    font-size: medium;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 5px;
  }
  .dashboardPieContainer {
    border-radius: 10px !important;
    float: none !important;
    position: top !important;
    padding-top: 5px !important;
    margin-bottom: 5px;
  }
}

g.recharts-layer.recharts-pie-sector {
  outline: none !important;
}
.testForViewTable{
  .datalist{
    position: relative;
    max-height: 48vh;
  }
  .datalistTable{
    max-height: '';
    height:'';
  }
}
