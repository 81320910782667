.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #878686;
  width: max-content;
  border-radius: 5px;
  height: 40px;
  flex-grow: 1;

  .search-bar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    font-size: 18px;
    color: #878686;
  }

  .search-bar-input {
    border: none;
    outline: none;
    padding-right: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #878686;
    // width: calc(100vw - 40px);
    width: 100%;
    // @media (max-width: 480px) {
    //   width: 100%;
    // }
  }

  .search-bar-icon-button {
    width: 0px;
    visibility: hidden;
    cursor: pointer;
    border: none;
    border-radius: 0px 5px 5px 0px;
    transition:  width .1s 0s;
    &:hover {
      background-color: #878686;
      color: white;
    }
    &[aria-expanded="true"] {
      width: 40px;
      visibility: visible;
    }
  }
}