@import '../../constants/constants.scss';
@import './langselect.scss';

.navigationbar-root {
    padding: 0.2em 1em;
    padding-bottom: 0px;
    background-color: $bg-color-main;
    color: $txt-color-main;
    box-shadow: 0px 1px 5px #2d2d2d4d;

    .navigationbar-logo {
        height: 4em;
    }

    .navigationbar-item {
        margin-top: auto;
        margin-bottom: auto;
        justify-content: end;
        text-transform: uppercase;
        margin-right: 24px;
    }

    /*#region Navbar overrides*/
    .navbar {
        padding: 0;
    }
    .nav-link {
        padding-bottom: 0;
    }
    .navbar-toggler {
        border: none;
        &:focus {
            box-shadow: none;
        }
    }

    .navbar-nav {
        gap: 14px;
    }
    /*#endregion Navbar overrides*/
}

.dark-theme {
    .navigationbar-root {
        background-color: $bg-color-dark;
        color: $txt-color-dark;
    }
}