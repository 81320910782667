.report-notice {
  display: flex;
  gap: 12px;
  background-color: #f4f4f4;
  color:#878686;
  font-size: 1rem;
  padding: 12px 24px;
  margin-bottom: 12px;
  border-radius: 5px;
  flex-direction: column;

  .notice {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 1.1rem;
    font-weight: 500;
    svg {
    color: #dc3545;
    }
  }
  .notice-clickable {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}