.reports {
  height: 100%;
  .reports-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .reports-title {
      font-size: 2em;
      font-weight: 600;
    }
  }
  .reports-toggle-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5;
    color: #878686;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
    }
    .reports-toggle {
      text-align: center;
      padding: 8px;
      height: inherit;
      font-weight: 700;
      &[aria-selected='true'] {
        background-color: #f8b133;
        border-radius: 5px;
        color: white;
        div {
          height: 2px;
          width: 100%;
          background: white;
          margin-top: -2px;
        }
      }
    }
  }
}
.customSwitch{
  .form-check-input {
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23666%27/></svg>") !important;
  }
  .form-check-input:checked {
    background-color: rgb(245, 156, 47) !important;
    border-color: rgb(245, 156, 47) !important;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27><circle r=%273%27 fill=%27%23fff%27/></svg>") !important;
  }
  
  .form-check-input:focus {
    border-color: #6668 !important;
    outline: 0;
    box-shadow: none !important;
  }
}