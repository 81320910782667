.newStyleToggle{
    background-color: white !important;
    color:black !important;
    min-width: 144px;
    border:4px solid #f4f4f4 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    font-weight: 450 !important;
}
body .newStyleToggle:checked  + .newStyleToggle:focus{
    background-color: white !important;
    color:black !important;
    min-width: 144px;
    border: 4px solid #f4f4f4 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}
.newStyleToggle:hover{
    background-color: #eeedeb !important;
    color:black !important;
    min-width: 144px;
    border: 4px solid #f4f4f4 !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

