.department-list {
  height: fit-content;
  .tool-button {
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    font-size: 14px;
    margin: 0px auto;
    border: none;
  }
}

.department-list > * {
  display: block;
  margin-top: 10px;
}

.department-list > *:first-child {
  margin-top: 0px;
}

.member-list {
  height: fit-content;
  .tool-button {
    padding: 0px;
    width: 30px;
    height: 30px;
    border-radius: 0px;
    font-size: 14px;
    margin: 0px auto;
    border: none;
  }
}

.member-list > * {
  display: block;
  margin-top: 10px;
}

.member-list > *:first-child {
  margin-top: 0px;
}