.custom-accordion{
  // background-color: white;
  background-color: #f4f4f4;
  padding: 0px 24px 0px;
  border-radius: 5px;
  border-bottom: 1px solid #f4f4f4;

  .titleWrapper{
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px 0px;
    display: flex;
    flex-flow: row nowrap;
    width: auto;
    height: auto;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
  .clickable {
    :hover{
      cursor: pointer;
    }
  }

  .title{
    color: #212529;
    margin-left: 10px;
    font-size: 1.25rem;
    font-weight: 500;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .contentWrapper{
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }

  .contentWrapper[aria-expanded="true"]{
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }

  .content{
    background-color: #f4f4f4;
    // border-radius: 2px;
  }

  

  .icon{
    // color: #212529;
    transition: all 0.25s ease;
    margin: 0px 15px 0px 0px;
  }

  .icon[aria-expanded="true"]{
    transform: rotateZ(-180deg);
  }
}