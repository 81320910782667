.departmentReportViewContainer{
    font-weight:500;
    font-size:1rem;
    position:relative;
    padding-right:8px;
    padding-left:8px;
    border:1px solid rgb(189, 190, 191,0.5);
    border-radius:5px;
    background-color:rgb(189, 190, 191,0.1);
    color:black;
}
.departmentReportViewIcon{
    color:#f7af32;
    margin-right:4px;
    margin-left:4px;
}