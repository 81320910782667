.styled-modal{
  .modal-body {
    font-size: 18;
    font-weight: 500;
    .submit-kickback-project-datacard {
      padding: 10px;
      align-items: center;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      border-radius: 5px;
      @media (min-width: 768px){
        // justify-content: start;
        justify-content: center;
      }
    }
    .submit-kickback-project-datacard-clickable {
      cursor: pointer;
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
  .modal-header {
    background: #f4f4f4;
    color: #878686
  }
  .modal-footer {
    button {
      min-width: 100px;
    }
  }
  .btn-close:focus {
    box-shadow: none;
  }
  .full-width {
    max-width: 95vw;
    .full-width-label {
      font-size: 20px;
      color: #878686;
    }
    
  
  }
}

.backdropped {
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
    position: absolute;
    top: 0px;
    z-index: 3;
    border-radius: calc(0.5rem - 1px);
  }
}

.submit-modal-body {
  font-size: 18px;
  font-weight: 600;
  margin: 0px 20px;
  td {
    padding-right: 12px;
  }
  .warning {
    color:#f8b133
  }
  .error {
    color:#b30404
  }
  textarea {
    &:focus {
      border-color: rgb(248, 177, 51);
      box-shadow: 0 0 0 0.25rem rgba(248, 177, 51,.25)
    }
  }
  .form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #f8b133;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23f8b133' d='M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z'/%3E%3C/svg%3E");
  }
  .form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    box-shadow: 0 0 0 .25rem rgba(248,177,51,.25);
  }
  .invalid-feedback {
    color: #f8b133;
  }
  .error-message {
    padding: 10px;
    background: #b30404;
    font-size: 14px;
    color: #ffffff;
    font-weight: 400;
    border-radius: .375rem;
    span {
      font-weight: 600;
      font-size: 16px;
      margin-right: 6px;
    }
  }
}
.templateModal {
  max-width: 600px;
}




.custom-typeahead-wrapper{
  min-width: 100% !important;
   .rbt-input-main.form-control.rbt-input{
    font-size:13px !important;
    min-width: 100% !important;
  }
}