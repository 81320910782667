.datalist {
  // padding: 0px 20px,
  // fontFamily: Utils.font,
  overflow-x: auto;
  border-radius: 5px;
  // height: 600px,
  height: 100%;
  // max-height: 60vh;
  overflow-y: auto;
  max-height: inherit;
  min-height: inherit;
  /* font-size: 16px; */
  overflow-anchor: none;
 
}

.datalistTable {
  height: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  table-layout: auto;
  th {
    padding: 0px 10px
  }
  td {
    padding: 0px 10px
  }
  @media (max-width: 1200px) {
    text-indent: 0px
  }
}

.datalistTableHead {
  height: 35px;
  vertical-align: middle;
  //font-size: 16px;
  font-size: 13px;
  color: #878686;
  background: #f4f4f4;
  top: 0px;
  position: sticky;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 3;
}

.datalistTableFoot {
  height: 35px;
  vertical-align: middle;
  //font-size: 16px;
  font-size: 13px;
  color: #878686;
  background: #f4f4f4;
  bottom: 0;
  position: sticky;
  th {
    padding: 0px 10px;
    white-space: nowrap;
  }
}

.datalistTableHeader {
  padding-right: 10px;
}

.datalistTableHeaderSortable {
  cursor: pointer;
  &:hover {
    color: #878686;
    text-decoration: underline;
  }
}

.datalistTableRow {
  //height: 40px;
  height: 30px;
  background: #ffffff;
  
  input {
    border: none;
    outline: none;
    max-width: 100%;
    padding: 0.1em;
    background-color: transparent;
    width: 100%;
    padding: 0.3em;

    &:focus {
        background-color: $table-hover-main;
    }
  }
}
.datalistTableRowHover{
  &:hover {
    background: #f4f4f4
  }
}

.datalistTableCell {
  border-bottom: solid 1px #f4f4f4;
  padding: 0px 10px 0px 0px;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .form-control {
    border: none;
    border-radius: 0px;
  }
  .rbt-input-multi.focus {
    box-shadow: none;
  }
}

.datalistTableCell[aria-disabled="true"] {
  background-color: #f9f9f9;

}

.dataListEmptyTable {
  background: #ffffff;
  margin: 0 auto;
  color: #878686;
  font-size: 28px;
  text-align: center;
  height: 200px;
}

.dataListLoading {
  background: #ffffff;
  margin: 0 auto;
  color: #878686;
  font-size: 28;
  text-align: center;
  height: 200px;
}