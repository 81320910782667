.supplier-bar {
  display: flex;
  background: rgb(244, 244, 244);
  flex-direction: row;
  font-size: 14px;
  color: rgb(135, 134, 149);
  gap: 20px;
  justify-content: end;
  align-items: center;
  padding: 2px 12px;
  border: 1px solid lightgrey;
}