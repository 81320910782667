.report-page {
  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
    }
}
  .section {
    box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
      0 0 0 1px rgba(9, 30, 66, 0.08);
    padding: 10px;
    border-radius: 5px;
    border-top: 5px solid #ffffff;
    // border-bottom: 5px solid #ffffff;
    // border: 3px solid #ffffff;
  }
  .kickback {
    border-color: $kickback-color;
  }
  .statistic {
    border-color: $statistic-color;
  }
  .zero {
    border-color: $zero-color;
  }
  .pulse {
    border-color: #56b53a;
    // box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
    //   0 0 0 1px rgba(9, 30, 66, 0.08);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
    padding: 10px;
    border-radius: 5px;
    border-top: 5px solid #56b53a;
    animation: pulse 4s infinite;
    transition: animation-duration 300ms;
    
  }
  .pulse:hover{
    //transition: all 1s ease-in-out;
    //animation-play-state: paused;
    animation-duration: 99s;
  }
}
