.invoice-barchart {
  height: 95%;
  
  .invoice-barchart-graph-wrapper {
    height: calc(100% - 48px);
    padding: 15px;
    //border-radius: 5px;
    //box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
   //margin:0; 
  }

  .recharts-responsive-container {
    // height: calc(100% - 48px) !important;
    // padding: 15px;
    // border-radius: 5px;
    // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    // width: calc(100% - 30px) !important;
    // height: calc(100% - 30px) !important;
    .recharts-surface {
      // width: calc(100% - 30px) !important;
      // height: calc(100% - 30px) !important;
      // width: calc(100%) !important;
      // height: calc(100%) !important;
    }
    .recharts-legend-wrapper {
      // bottom: 25px !important;
    }
  }

  .recharts-loading-container {
    height: calc(100% - 48px) !important;
    padding: 15px;
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  
  .invoice-barchart-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invoice-barchart-title {
      font-size: 2em;
      font-weight: 600;
    }
  }

  .invoice-barchart-tooltip {
    background: #ffffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 8px;
    border-radius: 5px;
  }

  .invoice-barchart-tooltip-header {
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
  }
  
  .invoice-barchart-tooltip-text {
    font-weight: 500;
    font-size: 16px;
    color: "#d2d2d2";
    padding: 4px;
  }

  .invoice-barchart-cell {
    fill: #00cdff;
    &:hover {
      fill: #00bcff;
    }
  }

  .invoice-barchart-legend-icon {
    background-color: #00cdff;
    width: 16px;
    height: 16px;
  }
  .invoice-barchart-legend {
    color: #00cdff;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    gap: 6px
  }
}

.invoiceFormat.btn.btn-primary{
  
  background-color: white !important;
  color:/*rgb(138,138,138,0.67)*/ rgb(170, 170, 170) !important;
  height:39.33px;
  width: 115px;
  //min-width: 50px;
  border:1px solid #ccc;             
  border-radius: 4px !important;
  box-shadow: none !important;
  font-weight: 450 !important;


}
 .invoiceFormat.show.btn.btn-primary{
  background-color: white !important;
  color:rgb(170, 170, 170) !important;
  height:39.33px;
  width: 115px;
  //min-width: 50px;
  border:2px solid rgb(0, 205, 255);             
  border-radius: 4px !important;
  box-shadow: none !important;

}

.textBlock{
  display:block;
  
  
}
.filterWrapper{
  margin: 5px;
  padding: 5px;
}
.filterContent{
  display:flex;
  margin:0;
  flex-direction: row;
  justify-content:center;
  
}
.textBlockTitle{
  width:50px;
  float:left;
  text-overflow:ellipsis;
  white-space:nowrap;
  overflow:hidden;
}
.textBlockIcon{
  float:right;
}
.datePicker{
  min-width: 50px;
  width: 115px;
  height:39.33px;
  display: flex;
  position: relative;
}
.datePickerZindex{
  height:39px; 
  position: absolute;
   z-index: 10;
   width: 100%;
   align-items:bottom;
}
.datePickerText{
  
  justify-content: center;
 
}
.multiSelect{
  width:115px;
  min-width:50px;
}
.outerbuddy{
  height:100%;
  border-radius: 5px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.rmsc{
  --rmsc-main : rgb(0, 205, 255);
}

@media screen and (max-width: 1200px) {
  
  .outerbuddy{
    height: 100%;
    

  }
  .invoice-barchart-graph-wrapper {
    height: calc(100% - 100px) !important;

  }
  .hstack {
    flex-direction: row;
    flex-wrap: wrap;
    
  
    .multiSelect,
    .invoiceFormat.btn.btn-primary {
      
 
      order: 2;
    }
  
    .datePicker,
    .datePickerZindex,
    .datePickerText,
    .datePickerBoard {
     
      order: 1;
    }
  }

  
}
@media screen and (max-width: 360px) {
  
  .outerbuddy{
    height: 100%;
    .invoice-barchart-graph-wrapper {
      height: calc(100% - 100px) !important;
      //padding: 15px;
      //border-radius: 5px;
      //box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
     //margin:0; 
     .recharts-responsive-container {
       height: calc(100% - 125px) !important;
      // padding: 15px;
      // border-radius: 5px;
      // box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
      // width: calc(100% - 30px) !important;
      // height: calc(100% - 30px) !important;
      .recharts-surface {
        // width: calc(100% - 30px) !important;
        // height: calc(100% - 30px) !important;
        // width: calc(100%) !important;
        // height: calc(100%) !important;
      }
      .recharts-legend-wrapper {
        // bottom: 25px !important;
      }
    }
    }

  }
 .dropdown-content{
  background-color: #000000 !important;
 }
 
 .hstack {
    
    flex-direction: row;
    flex-wrap: wrap-reverse;
    
  
    .multiSelect,
    .invoiceFormat.btn.btn-primary {
      
      order: 1;
    }
  
    .datePicker,
    .datePickerZindex,
    .datePickerText,
    .datePickerBoard {
      order: 2;
    }
  }

  
}
div.toasterContainer{
  position: relative ;
  z-index: 1000 ;
width: calc(95% - 30px) ;
float: right ;    
top:1;
    right:0px;
    

}
