.newsTabOveride {
  .nav-tabs {
    --bs-nav-tabs-link-active-color: rgb(0, 0, 0);
    font-size: 1em;
    border-bottom: none;
    
  }
  .nav-link {
    --bs-nav-link-color: rgb(214, 214, 214);
    border-bottom: none;
  }
  .nav {
    --bs-nav-link-hover-color: rgb(0, 0, 0);
  }
  .nav-link.active {
    box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
      border-bottom: none;
  }
}
.newsTabTitle {
  margin: 0px;
  padding: 0px;
  
}

.saveTab {
  margin-left: auto;
  gap: 20px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  height: inherit;
  bottom: 0;
  margin-bottom: 10px;
  padding: 4px;
  width: 100%;
}
.inactiveIcon {
  float: right;
  margin-left: 8px;
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.iconActive {
  float: right;
  margin-left: 8px;
  margin-right: 6px;
  width: 14px;
  height: 14px;
}

.titleInput {
  display:flex;
  width:100%;
  overflow: auto;
  font-size: 2.5rem;
  border-top: none;
  border-right: none;
  border-left: none;
 
  outline: none;
  flex-grow: 1;
  margin-right: 20px;

}
.titleInput:focus{
  background: rgb(242, 242, 242,0.3);
   box-shadow: rgb(242, 242, 242,0.3) 0px 7px 29px 0px;
  transition: box-shadow 1.5s ease-in-out;
}


.border{
	position: relative;
	font-size: 18px;
  border-bottom: white 2px solid;
}
@keyframes border_anim {
	0%{
		border-bottom: white 2px solid;
    // background: white;
    width: 0%;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
	}
	100%{
		border-bottom: 2px solid #e3e3e3;
    // background: aliceblue;
    width:100%;
    transform: scaleX(1);
	}
}
// @keyframes border_after {
// 	100%{
		
//     border-bottom: 2px solid #e3e3e3;
//     background: aliceblue;
// 	}
// 	0%{
// 		border-bottom: white 2px solid;
//     background: white;
// 	}
// }
.border:focus{
	// content: '';

	border-bottom: solid 2px #e3e3e3;
	animation: border_anim 1s 
}
.border:after{
  position: absolute;
  border-bottom: 2px solid #e3e3e3;
	//animation: border_after 1s linear forwards;
}


.tester{
  display: inline-block;
  width: 100%;
  overflow: hidden;
  border-bottom: white 2px solid;
  // transform: scaleX(0);
   
}
.tester:focus{
  border-bottom: 2px solid #e3e3e3;
}
.newsCard{
  margin:0 auto;
  max-width: 1200px;
  width: 100%;
 
  //transition-delay: 1s;
  
}
// .newsCard:hover{
//   max-width: 1200px;
//   width: 100%;
// }
.newsHeaderContainer{
  display:flex;
  align-content:center;
  justify-content:center;
  padding-bottom:14px;
  padding-top: 14px;
  transition: all  500ms ease-in-out;

 
}
.newsHeader{
  border-bottom:1px solid #e3e3e3;
  width:75%;
  text-align:center;
  padding-bottom:14px;
  transition: all  500ms ease-in-out;
}
.newsDivider{
  display:flex;
  align-content:center;
  justify-content:center;
  margin-top:14px;
  transition: all  500ms ease-in-out;
  
  
}
.newsDividerLine{
  border-bottom:1px solid #e3e3e3;
  width:75%;
  transition: all  500ms ease-in-out;
}
.newsDividerLineTop{
  border-top:1px solid #e3e3e3;
  width:75%;
  margin-top: 14px;
  transition: all  500ms ease-in-out;
}
.newsCreatorStack{
  justify-content:center;
  align-content:center;
  margin-top:14px;
  transition: all  500ms ease-in-out;
}
.newsicon{
  margin-right:8px;
  align-self:center;
  transition: all  500ms ease-in-out;
}
.newsIngress{
  font-style:italic;
  padding:0 170px;
  transition: all  500ms ease-in-out;
}
.newsBody{
  font-weight:500;
  padding:0 170px;
  transition: all  500ms ease-in-out;
}
@media screen and (min-width:992px){
  .newsIngress{
    padding:0 170px;
  }
  .newsBody{
    padding: 0 170px;
  }
}
@media screen and (max-width:768px) {
  .newsIngress{
    padding:0 80px;
  }
  .newsBody{
    padding: 0 80px;
  }
}

@media screen and (max-width:600px){
  .newsIngress{
    padding:0 30px;
  }
  .newsBody{
    padding: 0 30px;
  }
}



