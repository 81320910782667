
.fieldWrapper[aria-invalid="true"] {
  // color: red;
  outline: 1px solid #ea5219;
}

.fieldWrapper[aria-invalid="true"]::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 33px 33px 0px 0px;
  border-color: transparent #ea5219 transparent transparent;
  display: block;
  position: absolute;
  bottom: 0;
  right:0;
}

.fieldWrapper {
  position: relative;
  height: 40px;
  height: 100%;
  svg {
    color:white;
    position:absolute;
    bottom: 0;
    right: 1px;
    z-index: 2;
    width: 18px;
    height: 20px;
  }
  .form-control {
    box-shadow: none;
  }
  input{
    height: 40px;
    font-size: 1em;
  }
  input {
    border: none;
    outline: none;
    max-width: 100%;
    padding: 0.1em;
    background-color: transparent;
    width: 100%;
    padding: 0.3em;

    &:focus {
        background-color: $table-hover-main;
    }
}
}

.fieldWrapper-error{
  outline: 1px solid #b30404;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 33px 33px 0px 0px;
    border-color: transparent #b30404 transparent transparent;
    display: block;
    position: absolute;
    bottom: 0;
    right:0;
  }
}

.fieldWrapper-warn{
  outline: 1px solid #f8b133;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 33px 33px 0px 0px;
    border-color: transparent #f8b133 transparent transparent;
    display: block;
    position: absolute;
    bottom: 0;
    right:0;
  }
}
