$bg-color-main: white;
$txt-color-main: black;
$table-border-main: #e0e0e0;
$table-hover-main: #f4f4f4;

$bg-color-dark: #202124;
$txt-color-dark: white;
$table-border-dark: rgb(52, 52, 52);



:root{
  --kickback-color: #56b53a;
  --kickback-background: rgba(86, 181, 58, 0.051);
  --statistic-color: #30b0e7;
  --statistic-background:#30b0e70d;
  --zero-color: #f7af32;
  --zero-background:#f7af320d;
}
$kickback-color: var(--kickback-color);
$statistic-color: var(--statistic-color);
$zero-color: var(--zero-color);
  


