.changedLangPopup {
  bottom: 40px;
 position: fixed;
 width: 100%;
 height: fit-content;
 z-index: 1000;

  .changedLangPopupContent {
    margin: auto;
    width: 80vw;
    max-width: fit-content;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    display: flex;
    padding: 1em;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    justify-content: center;
  }

  .changedLangPopupText {
    font-size: 1.2em;
    font-weight: bold;
    color: #878686;
  }
}