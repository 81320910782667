
.styled-checkbox {
  cursor: pointer;
  font-family: system-ui, sans-serif;
  font-size: 1.24rem;
  font-weight: 500;
  line-height: 1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;

  input[type="checkbox"] {
    cursor: pointer;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: #878686;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #878686;
    border-radius: 0.15em;
    transform: translateY(-0.075em);

    display: grid;
    place-content: center;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #f8b133;
    background-color: #f8b133;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
