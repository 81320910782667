.footer {
  background-color: #f4f4f4;
  margin-top: auto;

  .container {
      width: 80%;
  }

  .footer-items{
      padding: 4% 0 0;
      display: flex;
      flex-wrap: wrap;
  }

  .footer-item {
      font-weight: 500;
      line-height: 1.5em;
      width: 20.8%;
      margin-right: 5.5%;
      margin-bottom: 5.5%;
      font-size: 14px;
      color: #7c7b7b;
      @media (max-width: 1200px){
          width: 42.25%;
          margin-right: 7.5%;
          margin-bottom: 7.5%;
      }

      @media (max-width: 752px){
          width: 100%;
          margin-bottom: 9.5%;
      }

      div {
          word-wrap: break-word;
      }
      h4 {
          font-size: 16px;
          padding-bottom: 10px;
          line-height: 1em;
          margin: 0;
          vertical-align: baseline;
          @media (max-width: 1200px){
              font-size: 24px;
          }

          @media (max-width: 752px){
              font-size: 17px;
          }
      }
      p {
          padding-bottom: 1em;
          margin: 0;
      }
      ul {
          position: relative;
          padding-left: 0;
          list-style: none;
      }
      li {
          margin-bottom: .5em;
      }
      a {
          color: #7c7b7b;
          text-decoration: none;
      }
  }

  .footer-item:last-child {
      margin-right: 0px;
  }
}