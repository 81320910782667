.mambaJamba {
  // margin: 0px !important;
  // position: relative !important;
  .form-control:focus {
    //border: 2px solid;
    border-color: rgb(252, 251, 251) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    height: 100% !important;
    //    margin: 0 !important;
    //    padding: 0 !important;
  }
  .form-control {
    height: 100% !important;
    border-radius: 0 !important;
    font-size: 13px !important;
  }
  .input.rbt-input-main.form-control.rbt-input {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    //font-size: 13px !important;
  }
  .input.rbt-input-main.form-control.rbt-input.focus {
    margin: 0 !important;
    padding: 0 !important;
    //font-size: 13px !important;
    border-radius: 0 !important;
    height: 100%;
  }
  .datalistTableCell {
    padding: 0 !important;
    margin: 0 !important;
  }
  .td.datalistTableCell {
    padding: 0 !important;
    margin: 0 !important;
  }
  .datalistTable td {
    padding: 0px !important;
    margin: 0px !important;
  }
  .dropdown-menu {
    --bs-dropdown-font-size: 13px;
    font-size: --bs-dropdown-font-size;
  }
}
.statisticSection {
  border-radius: 5px;
  // border-top: 5px solid $statistic-color;
  // box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25), 0 0 0 1px rgba(9, 30, 66, 0.08);
  .table-wrapper {
    //height: 20px !important;
    // min-height: 40vh !important;
    // min-height: 200px;
    max-height: 400px;
    // max-height: 10vh !important;
    margin-bottom: 20px;
  }
}

.tabStyleOveride {
  .nav-tabs {
    --bs-nav-tabs-link-active-color: rgb(0, 0, 0);
    font-size: 1em;
  }
  .nav-link {
    --bs-nav-link-color: rgb(214, 214, 214);
  }
  .nav {
    --bs-nav-link-hover-color: rgb(0, 0, 0);
  }
  .nav-link.active {
    box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 1px,
      rgb(209, 213, 219) 0px 0px 0px 1px inset;
    // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px ;
  }
}
.tabTitle {
  margin: 0px;
  padding: 0px;
  // .nav-tabs.nav-link.active{
  //     color:aqua
  // }
  // .nav-link{
  //     color:rgb(255, 127, 172);
  // }
}
.rowsAddedIcon {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: rgb(7, 162, 53, 0.2);
  width: "14px";
  height: "14px";
}
.rowsAddedIconActive {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: rgb(7, 162, 53, 0.8);
  width: "14px";
  height: "14px";
}
.rowInvalidActive {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: rgb(248, 176, 51, 0.8);
  width: "14px";
  height: "14px";
}
.rowInvalidInactive {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: rgb(248, 176, 51, 0.2);
  width: "14px";
  height: "14px";
}
$initIconColor: rgb(179, 4, 4, 0.2);
$activeInitColor: rgb(179, 4, 4, 0.8);
.initIcon {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: $initIconColor;
  width: "14px";
  height: "14px";
}
.initIconActive {
  float: right;
  margin-left: 8;
  margin-right: 6;
  color: $activeInitColor;
  width: "14px";
  height: "14px";
}
.initIcon:focus,
.initIcon:active {
  color: $activeInitColor;
}
.rotorMotor {
  transform: rotate(180deg);
}
.rotorbackDa {
  animation: mymove;
}
@keyframes mymove {
  50% {
    transform: rotate(180deg);
  }
}
.ladida {
  input[type="date"] {
    position: relative;
    padding: 10px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  input[type="date"]:before {
    color: transparent;
    background: none;
    display: block;
    font-family: "FontAwesome";
    content: "\f073";
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 6px;
    color: #999;
  }
}
.numberField {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.checkYeah {
  .form-check-input{
    // height:24px;
    // width:25px;
    margin: 0;
    padding:0;
    height:26px;
    width:25px;
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: rgb(245, 156, 47) !important;
    border-color: rgb(245, 156, 47);
  }
  .form-check-input:focus {
    border-color: rgb(245, 156, 47);
  }
  .form-check-input:active {
    border-color: rgb(245, 156, 47);
  }
  // .form-check{
  //   .form-check-input.is-valid:focus{
  //     //background-color: rgba(146, 192, 31) !important;
  //     box-shadow: 0 0 0 .025rem rgba(146, 192, 31,0.15) !important;
  //   }
  //   .form-check-input.is-valid:checked{
  //     //background-color: rgba(146, 192, 31) !important;
  //     background-color:  rgba(146, 192, 31) !important;
  //   }
  // }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.15rem rgb(245, 156, 47, 0.1) !important;
  }
}

.infoCardWrapper {
  box-shadow: rgba(0, 0, 0, 0.15) 3px 8px 8px 3px;
  //  display: flex;
  //  flex-direction: column;
  // // justify-content: center;
  height: 10rem;
  // border-radius: 5px;
  // width:25rem;
  //box-shadow: 0 0 0 .15rem rgba(159, 158, 157, 0.5);
  //position: relative;
  //top: 110px;
  width: 15rem;
  display: block;
  margin: auto;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  //font-family: 'Source Sans Pro',
}
.infocardContent {
  // z-index: 1;
  //height: fit-content;

  // background-color: #afc770;
  // align-items: center;
  width: 100%;
  // height: 100%;
  // padding: 2em;
  background-color: #8bc34a;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  //border-radius: 20px;
  //margin-right: 5px;
  //width: 75%;
  //height: 100%;
}
.infocardText {
  //position: absolute;
  //color: #92c01f;

  padding-top: 25;
  margin-top: 25px;
  height: 150;
  border-bottom: 1px solid #8bc34a;
}
.infocardTextError {
  margin-top: 25px;
  height: 150;
  border-bottom: 1px solid #b30404;
}
.duplicate {
  background-color: #b30404;
}

.statsCardsContainer {
  width: "100%";
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
}

.statsCards {
  --outset-size: 0.75rem;
  width: "25%";
  height: 10rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  border: 1px solid black;
  padding-right: 10px;

  .icon {
    font-size: 38px;
  }
}

.submitModalInfoCardContainer {
  border-top: 5px solid rgb(146, 192, 31, 0.8);
  border-left: 0.5px solid rgb(146, 192, 31, 0.15);
  border-right: 0.5px solid rgb(146, 192, 31, 0.15);
  border-bottom: 0.5px solid rgb(146, 192, 31, 0.15);
  
  // overflow: hidden;
  width: "100%";
  padding: 10px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 15px;
  
  // display: flex;
  // justify-content: flex-start;
  .infoIcon {
    color: rgba(146, 192, 31);
    font-size: 22px;
    padding: 5px;
  }

  .paragraph {
    font-size: 16px;
    margin-left: 10px;
    padding-bottom: 5px;
  }
  .infoLielement{
    margin-left: 6px;
  }
  .submitModalInfoCardContentActive {
   
    background-color: rgb(255, 255, 255);
   
    border-radius: 5px;
    padding: 5px;
    justify-content: center;
    width: 100%;
    // -webkit-transition: max-height 0.5s; 
    // -moz-transition: max-height 0.5s; 
    // -ms-transition: max-height 2s; 
    // -o-transition: max-height 2s; 
    transition: max-height 1s  ease-in-out;  
   
    overflow: hidden;
    // "height: 0" not work with css transitions
   
   
  }
  .submitModalInfoCardContentInActive {
    display: none;
    cursor: pointer;
    max-height: 0px;
    transition: max-height 200ms ease-in-out;
    // height: 200px;
    // width: 200px;
    // background-color: teal;
    // display: inline-block;
    //   -webkit-animation: conditionalClose 1s normal forwards ease-in-out;
    //      -moz-animation: conditionalClose 1s normal forwards ease-in-out;
    //           animation: conditionalClose 1s normal forwards ease-in-out;
    //   -webkit-transform-origin: 100% 0%;
    //      -moz-transform-origin: 100% 0%;
    //           transform-origin: 100% 0%;
  }
  
}
@import "https://fonts.googleapis.com/css2?family=Merriweather:ital@1&family=Oswald:wght@500&display=swap";
$color_1: var(--card-text-color);
$color_2: var(--accent-color);
$color_3: var(--text-color);
$font-family_1: var(--number-font-family);
$font-family_2: "Oswald", sans-serif;
$font-family_3: "Merriweather", serif;
$background-color_1: var(--card-background-color);
$background-color_2: var(--outset-background-color);
$background-color_3: #f5f5f5;
$liValue: var(--value);

.testTestTest {
  /* grid layout */
  /* card layout/styling */
  /* card content */
  /*  */
  ol {
    // margin: 0;
    // padding: 0;

    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    gap: 1rem;
    width: fit-content;
    margin-inline: auto;
    padding-block: 1rem;
    list-style: none;
    counter-reset: count;

    > li {
      --card-background-color: #f0f0f0;
      --card-text-color: #0f0f0f;
      --card-border-radius: 0.5rem;
      --card-padding-block: 1.5rem;
      --card-padding-inline: 1rem;
      --outset-size: 0.75rem;
      --outset-background-color: #e5e5e5;
      --number-font-size: 3rem;
      --number-overlap: 0.5rem;
      --number-font-family: "Oswald", sans-serif;
      --number-font-weight: 500;
      border-radius: var(--card-border-radius);
      padding-block: var(--card-padding-block);
      padding-inline: var(--card-padding-inline);
      color: $color_1;
      background-color: $background-color_1;
      box-shadow: inset 1px 1px 0px rgb(255 255 255 / 0.5),
        inset -1px -1px 0px rgb(0 0 0 / 0.25),
        calc(var(--outset-size) * 0.25) calc(var(--outset-size) * 0.25)
          calc(var(--outset-size) * 0.5) rgb(0 0 0 / 0.25);
      position: relative;
      height: 250px;
    }
  }

  .icon {
    font-size: 2rem;
    text-align: center;
    margin-bottom: calc(var(--card-padding-block) * 0.5);
  }
}

.value {
  text-transform: uppercase;
  font-family: $font-family_2;
  text-align: center;
  font-size: 1.3em;
}
.textTitle {
  margin: 0;
  padding: 0;
  font-size: 1em;
  text-transform: uppercase;
  // font-weight: 700;
  font-family: $font-family_2;
}
.commentBox {
  box-sizing: border-box;
  display: grid;

  gap: 1rem;
  width: "50%";
  margin-inline: auto;
  padding-block: 1rem;
}

// input[type="textarea"]:focus{
//   border-color: #8bc34a;
// }
// input[type="textarea"]:active{
//   border-color: #8bc34a;
// }
textarea.invoiceReportComment:active {
  // border-color: rgb(245, 156, 47);
  border-radius: 2px;
  border: 1.8px solid rgb(245, 156, 47);
  outline: none;
  //   outline:  10px;
  //  outline-color: rgb(245, 156, 47,0.2);
  //   border-radius: 2px;
  //   box-shadow: rgb(245, 156, 47) 0px 1px 3px, rgb(245, 156, 47) 0px 1px 2px;
}
// textarea.invoiceReportComment:after{
//   border-color: rgb(245, 156, 47);
//   outline: none;
//   box-shadow: 0 0 10px rgb(245, 156, 47);
//   //color: #b30404;
// }
textarea.invoiceReportComment {
  margin-top: 2px;
}
textarea.invoiceReportComment:focus {
  border: 1.8px solid rgb(245, 156, 47);
  border-radius: 2px;
  outline: none;
  // outline-color: rgb(245, 156, 47,0.2);

  box-shadow: rgb(245, 156, 47, 0.2) 3px 3px 8px,
    rgb(245, 156, 47, 0.2) 3px 3px 8px;
  /*box-shadow: 0 0 10px rgb(245, 156, 47,0.2);*/
}
.saveButton {
  .btn.btn-primary {
    background-color: rgb(86, 181, 58, 0.8);
  }
  background-color: rgb(86, 181, 58, 0.8);
}

.verifyButton.btn {
  background-color: rgb(146, 192, 31, 0.8);
  border-color: rgb(146, 192, 31);
}
.verifyButton.btn:hover {
  background-color: rgb(146, 192, 31);
  border-color: rgb(146, 192, 31);
}
.verifyButton.btn:focus {
  background-color: rgb(146, 192, 31, 0.8);
  border-color: rgb(146, 192, 31,0.8);
  box-shadow: 0 0 0 0.25rem rgb(146, 192, 31, 0.2);
}
.verifyButton.btn:active {
  background-color: rgb(146, 192, 31, 0.8);
  border-color: rgb(146, 192, 31,0.8);
  box-shadow: 0 0 0 0.25rem rgb(146, 192, 31, 0.2);
}
.verifyButton.btn:disabled{
  border-color: #a1b2b9;
  background-color: #a1b2b9;
  color: #0a0a0a;
}
.verifyTable {
  border-left: 1px solid rgb(244, 244, 244);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-top: 5px solid rgb(55, 153, 223);
}
.numberFieldTest {
  .input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
}
.exclamationIconStyle {
  color: #fff;
  font-size: 20;
  right: 0;
  top: 20;
  padding-bottom: 0;
}
.projectNameDiv {
  cursor: pointer;
  height: 40px;
  align-items: center;
  display: flex;
  padding: 0.3em;
  position: relative;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.commentInputField {
  height: 100%;
  align-items: center;
  display: flex;
  border-radius: 0px;
  border: none;
  resize: horizontal;
}
.projectEnddateField {
  width: 4000;
  cursor: pointer;
  padding-right: 30;
}
.contractDiv {
  cursor: pointer;
  height: 100%;
  align-items: center;
  display: flex;
  padding: 0.3em;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 125px;
  .contractSpan {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.project-report-wrapper {
  .section {
    box-shadow: 0 4px 8px -2px rgba(9,30,66,.25), 0 0 0 1px rgba(9,30,66,.08);
    padding: 10px;
    border-radius: 5px;
    border-top: 5px solid #ffffff;
    // border-bottom: 5px solid #ffffff;
    // border: 3px solid #ffffff;
  }
  .kickback {
    border-color: $kickback-color;
  }
  .statistic {
    border-color:  $statistic-color;
  }
  .zero {
    border-color: $zero-color;
  }
}
.checkBoxText {
  margin-left: 4px;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  font-size: 21px;
}





.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-name: animate-fade;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-fill-mode: backwards;
}
@keyframes animate-fade {
  0% { opacity: 0; }
100% { opacity: 1; }
}
@-webkit-keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }
        
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
/* Animation Delays */
.delay-1 {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.7s;
}
.delay-2 {
  animation-delay: 0.7s;
  -webkit-animation-delay: 0.8s;
}
.delay-3 {
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.9s;
}
.delay-4 {
  animation-delay: 0.9s;
  -webkit-animation-delay: 1s;
}
.delay-5 {
  animation-delay: 1s;
  -webkit-animation-delay: 1.1s;
}
.delay-6 {
  animation-delay: 1.1s;
  -webkit-animation-delay: 1.2s;
}
.delay-7 {
  animation-delay: 1.2s;
  -webkit-animation-delay: 1.3s;
}
.delay-8 {
  animation-delay: 1.3s;
  -webkit-animation-delay: 1.4s;
}
.delay-9 {
  animation-delay: 1.4s;
  -webkit-animation-delay: 1.5s;
}
.delay-10 {
  animation-delay: 1.5s;
  -webkit-animation-delay: 1.6s;
}
.delay-11 {
  animation-delay: 1.6s;
  -webkit-animation-delay: 1.7s;
}
.delay-12 {
  animation-delay: 1.7s;
  -webkit-animation-delay: 1.8s;
}
.delay-13 {
  animation-delay: 1.8s;
  -webkit-animation-delay: 1.9s;
}
.delay-14 {
  animation-delay: 1.9s;
  -webkit-animation-delay: 2s;
}
.delay-15 {
  animation-delay: 2s;
  -webkit-animation-delay: 2.1s;
}