.toasterContainer{
    position: absolute;
    width: 15%;
    top:100px;
    right:0px;
    height: 5%;
    margin-right: 28px; 
}
@keyframes expand {
    0% {opacity: 0;width: 0%;}    
    25% {opacity: 0.3; }
    50% {opacity: 0.4; }
    60%{opacity: 0.5; }
    75% {opacity: 0.6;  }
    85%{opacity: 0.7; }
    100% {width: 100%; }
}
.toasterContentContainer{
    text-align: center;
    position: absolute;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    right:0;
    width: 100%;
    animation-name: expand;
    animation-duration: 1.5s;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    opacity: 0.8;
}

@keyframes toasterMessage{
    //visibility: hidden;
    0% { opacity: 0; width: 0%;}
    25% {opacity: 0.2;}
    50% {opacity: 0.5;}
    60%{opacity: 0.6;}
    75% {opacity: 0.7;}
    85%{opacity: 0.8;}
    90%{width:90%;}
    95%{width:95%;}
    100% {width:100%}
}
.toasterMsg{
    
    font-size: small;
    color: #ffffff;
    white-space: nowrap;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    animation-name: toasterMessage;
    animation-duration: 1.5s;
}



@keyframes toasterClose{
    100%{width: 100%;opacity: 0.8; }  
    0%{width: 0%;opacity: 0;}
}

.toasterCloseContainer{
    text-align: center;
    position: absolute;
    border: 2px solid #f4f4f4;
    border-radius: 8px;
    right:0;
    width: 0;
    animation-name: toasterClose;
    animation-duration: 1.5s;
    animation-direction: alternate-reverse;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    opacity: 0.8;
}
.marginWrapper{
    margin: 5px 25px 10px 15px;
   
}
@media only screen and (max-width:600px){
    .toasterContainer{
        width: 45%;
        
    }
    .marginWrapper{
         margin:5px  5px 10px;
    }
}
@media only screen and (min-width:600px){
    .toasterContainer{
        width: 30%;
    }
}
@media only screen and (min-width: 1200px) {
    .toasterContainer{
        width:15%;
        
    }
   
}
