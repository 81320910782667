


.report-filter{
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 0px 8px;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1), margin 0.6s cubic-bezier(0, 1, 0, 1), padding 0.6s cubic-bezier(0, 1, 0, 1);

  
  .report-filter-title {
    font-size: 18px;
    font-weight: 600;
  }
}
.report-filter[aria-expanded="true"]{
  padding: 12px;
  margin: 8px 0px;
  max-height: 1000px;
  transition: max-height 1s ease-in-out, margin 0.5s ease-in-out, padding 0.5s ease-in-out;
  // transition: margin 1s ease-in-out;
}