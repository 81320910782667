.comment-wrapper {
  background: #f4f4f4;
  padding: 5px 10px;
  border-radius: 5px;
  width: max-content;
  border: 1px solid #e1e1e1;
  margin-top: 10px;
  color:#878686;
  font-style: italic;

  .comment-text {
    font-size: 14px;
  }
}